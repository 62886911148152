<template>
  <div class="placeEdit">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="所属分类"
        :rules="[
          { required: true, message: '请选择所属分类', trigger: 'blur' },
        ]"
        prop="categoryId"
      >
        <v-select
          @change="categoryIdChange"
          :options="mapTypeList"
          v-model="form.categoryId"
        />
      </el-form-item>
      <el-form-item
        label="选择区级"
        :rules="[{ required: true, message: '请选择区级', trigger: 'blur' }]"
        prop="districtCode"
      >
        <v-select
          @change="districtCodeChange"
          @clear="districtCodeclear"
          :options="districtListOptions"
          v-model="form.districtCode"
        />
      </el-form-item>
      <el-form-item
        label="选择街道"
        :rules="[{ required: true, message: '请选择街道', trigger: 'blur' }]"
        prop="streetId"
      >
        <v-select
          @change="streetIdChange"
          @clear="streetIdClear"
          :options="streetListOptions"
          v-model="form.streetId"
        />
      </el-form-item>
      <el-form-item
        label="选择社区"
        :rules="[{ required: true, message: '请选择社区', trigger: 'blur' }]"
        prop="structuringId"
      >
        <v-select
          @clear="structuringIdClear"
          :options="structuringOptions"
          v-model="form.structuringId"
        />
      </el-form-item>
      <!-- <el-form-item
        label="所属园区"
        v-if="form.categoryId"
        :rules="[
          { required: true, message: '请选择所属园区', trigger: 'blur' },
        ]"
        prop="communityId"
      >
        <community-select
          v-model="form.communityId"
          @change="getPlaceTypeOptions"
          label="选择园区"
        />
      </el-form-item> -->
      <el-form-item
        v-if="form.categoryId"
        label="类型"
        :rules="[
          { required: true, message: '请选择所属类型', trigger: 'blur' },
        ]"
        prop="placeTypeId"
      >
        <v-select :options="placeTypeOptions" v-model="form.placeTypeId" />
      </el-form-item>
      <el-form-item
        label="场地名称"
        :rules="[
          { required: true, message: '请输入场地名称', trigger: 'blur' },
        ]"
        prop="name"
      >
        <v-input
          placeholder="请输入场地名称"
          v-model="form.name"
          :width="250"
          :maxlength="20"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="场地图片"
        :rules="[
          { required: true, message: '请上传场地图片', trigger: 'blur' },
        ]"
        prop="placePicUrls"
      >
        <div id="activityImg">
          <v-upload :limit="9" :imgUrls.sync="form.placePicUrls" />
        </div>
      </el-form-item>

      <el-form-item
        label="场地地址"
        :rules="[
          { required: true, message: '请输入场地地址', trigger: 'blur' },
        ]"
        prop="address"
      >
        <v-input
          placeholder="请输入场地地点"
          v-model="form.address"
          :width="350"
          :maxlength="50"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="场地面积"
        :rules="[
          { required: true, message: '请输入场地面积', trigger: 'blur' },
        ]"
        prop="area"
      >
        <v-input
          placeholder="请输入场地面积"
          v-model="form.area"
          :width="150"
          type="number"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="场地人数"
        :rules="[
          { required: true, message: '请输入场地人数', trigger: 'blur' },
        ]"
        prop="count"
      >
        <v-input
          placeholder="请输入场地人数， 0-表示不限制"
          v-model="form.count"
          :width="150"
          type="number"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="场地管理员"
        :rules="[
          { required: false, message: '请输入场地管理员', trigger: 'blur' },
        ]"
        prop="contactPerson"
      >
        <v-input
          placeholder="请输入场地管理员"
          v-model="form.contactPerson"
          :width="150"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="管理员联系方式"
        :rules="[
          { required: false, message: '请输入管理员联系方式', trigger: 'blur' },
        ]"
        prop="contactPhone"
      >
        <v-input
          placeholder="请输入管理员联系方式"
          v-model="form.contactPhone"
          :width="350"
          type="number"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="营业时间"
        :rules="[
          { required: false, message: '请输入营业时间', trigger: 'blur' },
        ]"
        prop="bankingHours"
      >
        <v-input v-model="form.bankingHours" />
      </el-form-item>
      <el-form-item label="场地介绍" prop="spaceDescription">
        <div id="editor">
          <v-ueditor :maxLength="20000" v-model="form.spaceDescription" />
        </div>
      </el-form-item>

      <el-form-item
        label="是否作为活动场馆"
        :rules="[{ required: false, message: '', trigger: 'blur' }]"
        prop="isActivityRoom"
      >
        <v-radio :radioObj="radioObj" v-model="form.isActivityRoom"></v-radio>
      </el-form-item>
      <el-form-item label="关联的空间">
        <div class="address">
          <div class="item">
            <div class="item-l">{{ form.spaceTxt }}</div>
            <div class="item-r">
              <v-button
                v-if="form.spaceTxt"
                text="删除"
                @click="outRoom"
                type="warning"
              ></v-button>
              <v-button
                text="添加"
                v-if="!form.spaceTxt"
                @click="addRoom"
              ></v-button>
              <v-button
                v-if="form.spaceTxt"
                text="更换"
                @click="addRoom"
              ></v-button>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="场地设施">
        <el-tag
          :key="item"
          v-for="item in form.equipList"
          closable
          :disable-transitions="false"
          @close="handleEquipClose(item)"
        >
          {{ item }}
        </el-tag>
        <v-input
          class="input-new-tag"
          v-if="equipInputVisible"
          v-model="equipInputValue"
          ref="saveTagInput"
          @keyup.enter.native="handleEquipInputConfirm"
          @blur="handleEquipInputConfirm"
        >
        </v-input>
        <v-button
          v-else
          class="button-new-tag"
          size="small"
          @click="showEquipInput"
          text="+设施"
        ></v-button>
      </el-form-item>
      <el-form-item
        label="请选择预约方式"
        :rules="[
          { required: true, message: '请选择预约方式', trigger: 'change' },
        ]"
        prop="bookingType"
      >
        <v-select :options="appointWay" v-model="form.bookingType" />
      </el-form-item>
      <template v-if="form.bookingType == 1">
        <el-form-item label="开放的星期" prop="openDaysList" :rules="oRules">
          <el-checkbox-group v-model="form.openDaysList">
            <el-checkbox label="1">周一</el-checkbox>
            <el-checkbox label="2">周二</el-checkbox>
            <el-checkbox label="3">周三</el-checkbox>
            <el-checkbox label="4">周四</el-checkbox>
            <el-checkbox label="5">周五</el-checkbox>
            <el-checkbox label="6">周六</el-checkbox>
            <el-checkbox label="7">周日</el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item
          label="开放天数"
          :rules="[
            { required: true, message: '请输入开放天数', trigger: 'blur' },
          ]"
          prop="openDays"
        >
          <v-input
            placeholder="请输入开放天数（会生成相应的日期的场次数据）"
            v-model="form.openDays"
            :width="150"
            type="number"
            :max="90"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="开放截止日期"
          :rules="[
            { required: true, message: '请选择截止日期', trigger: 'blur' },
          ]"
          prop="endDate"
        >
          <v-date v-model="form.endDate"></v-date>
        </el-form-item>
        <el-form-item
          label="是否收费"
          :rules="[
            { required: true, message: '请选择收费类型', trigger: 'blur' },
          ]"
          prop="payType"
        >
          <v-radio
            :radioObj="payTypeObj"
            @change="changePayType"
            v-model="form.payType"
          ></v-radio>
        </el-form-item>
        <el-form-item label="场次">
          <v-button
            size="medium"
            type="success"
            icon="el-icon-plus"
            text="添加场次"
            @click="showScheduleAddView"
          >
          </v-button>
          <div style="width: 500px">
            <el-table
              class="el-table"
              :data="form.scheduleInfoList"
              element-loading-text="Loading"
              border
              highlight-current-row
            >
              <el-table-column align="center" width="60">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column label="开始时间" align="center" width="100">
                <template slot-scope="scope">
                  {{ scope.row.beginTime }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="结束时间" width="100">
                <template slot-scope="scope">
                  {{ scope.row.endTime }}
                </template>
              </el-table-column>
              <el-table-column
                v-if="form.payType == 1"
                align="center"
                label="收费价格"
                width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.schedulePrice }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="操作"
                class-name="small-padding"
              >
                <template slot-scope="scope">
                  <div class="dialogBottomBtn">
                    <v-button
                      type="primary"
                      size="mini"
                      v-if="scope.row.canEdit == undefined || scope.row.canEdit"
                      @click="showScheduleEditView(scope.$index)"
                      text="编辑"
                    >
                    </v-button>
                    <v-button
                      size="mini"
                      type="danger"
                      @click="deleteScheduleTempData(scope.$index)"
                      text="删除"
                    >
                    </v-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
      </template>
      <template v-if="form.bookingType == 2">
        <el-form-item
          label="联系电话"
          :rules="[
            { required: true, message: '请输入联系电话', trigger: 'blur' },
          ]"
          prop="bookingTel"
        >
          <v-input
            placeholder="请输入联系电话"
            v-model="form.bookingTel"
            :width="250"
            type="number"
          ></v-input>
        </el-form-item>
      </template>
      <template v-if="form.bookingType == 3">
        <el-form-item
          label="外部跳转网址"
          :rules="[
            { required: true, message: '外部跳转网址', trigger: 'blur' },
          ]"
          prop="bookingLinkUrl"
        >
          <v-input
            placeholder="请输入外部跳转网址"
            v-model="form.bookingLinkUrl"
            :width="250"
          ></v-input>
        </el-form-item>
      </template>
      <template v-if="form.bookingType == 4">
        <el-form-item
          label="描述文字"
          :rules="[{ required: false, message: '描述文字', trigger: 'blur' }]"
          prop="bookingDesc"
        >
          <v-input
            placeholder="请输入描述文字"
            v-model="form.bookingDesc"
            :width="250"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="图片"
          :rules="[{ required: false, message: '图片', trigger: 'blur' }]"
          prop="bookingImg"
        >
          <v-upload :limit="9" :imgUrls.sync="form.bookingImg" />
        </el-form-item>
        <el-form-item
          label="视频"
          :rules="[{ required: false, message: '视频', trigger: 'blur' }]"
          prop="bookingVideo"
        >
          <v-videoUpload :limit="1" :videoUrls.sync="form.bookingVideo" />
        </el-form-item>
      </template>
    </form-panel>

    <v-dialog
      title="场次信息编辑"
      v-model="dialogFormVisible"
      width="50%"
      @confirm="saveScheduleTempData"
    >
      <el-form label-position="right" label-width="100px">
        <el-form-item label="开始时间">
          <v-time-old v-model="tempScheduleObj.beginTimeDate"></v-time-old>
        </el-form-item>

        <el-form-item label="结束时间">
          <v-time-old v-model="tempScheduleObj.endTimeDate"></v-time-old>
        </el-form-item>
        <el-form-item label="场次收费" v-if="form.payType == 1">
          <v-input
            type="number"
            v-model="tempScheduleObj.schedulePrice"
          ></v-input>
        </el-form-item>
      </el-form>
    </v-dialog>
    <v-dialog
      title="空间"
      class="realNameAuditForm-addAddress"
      v-model="isdialog"
      @confirm="confirm"
      cancelTxt="取消"
      sureTxt="更换"
    >
      <area-select
        @changeModel="changeModel"
        ref="areaSelect"
        :specifyCollectionTypes="specifyCollectionTypes"
        :isFlex="false"
      />
      <!-- <roomLevel
        ref="roomLevel"
        @change="changeData"
        :isShowRelation="false"
      ></roomLevel> -->
    </v-dialog>
  </div>
</template>

<script>
import {
  getStreetCommunityListUrl,
  getPlaceTypeListUrl,
  placeDetailByIdUrl,
  editPlaceUrl,
  getPlaceTypeUrl,
  getRentDetailUrl,
  getCommunityListByTenantIdUrl,
} from "./api";
import roomLevel from "@/components/bussiness/roomLevel/roomLevel.vue";
import { placeStatus, appointWayMap, appointWayOps, appointWay } from "./map";
import { Checkbox, CheckboxGroup } from "element-ui";

export default {
  name: "placeEdit",
  data() {
    let validay = (rule, value, callback) => {
      console.log(value);
      if (value.length == 0) {
        callback(new Error("请选择开放星期"));
      } else {
        callback();
      }
    };
    return {
      isdialog: false,
      specifyCollectionTypes: "1",
      uploadURL: "",
      mapTypeList: [],
      submitConfig: {
        queryUrl: placeDetailByIdUrl,
        submitUrl: editPlaceUrl,
      },
      appointWay,
      payTypeObj: [
        {
          name: 2,
          value: "免费",
        },
        {
          name: 1,
          value: "收费",
        },
      ],
      radioObj: [
        {
          name: 0,
          value: "不是",
        },
        {
          name: 1,
          value: "是",
        },
      ],
      oRules: [
        {
          required: true,
          message: "请选择开放星期",
          trigger: "change",
        },
        {
          validator: validay,
          trigger: "change",
        },
      ],
      allList: [],
      houseNameList: [1],
      form: {
        payType: 2,
        oSpaceId: "",
        isLast: "",
        isActivityRoom: 0,
        spaceId: "",
        spaceTxt: "",
        path: "",
        bankingHours: "",
        spaceDescription: "",
        id: null,
        bookingType: "",
        categoryId: "",
        communityId: "",
        placeTypeId: null,
        name: null,
        address: null,
        area: null,
        count: null,
        status: 1,
        openDays: null,
        openTime: null,
        endDate: null,
        equip: null,
        equipList: [],
        pictureBeanList: [],
        placePicUrls: "",
        placePicList: [],
        offerServer: "",
        orderProcess: "",
        price: "",
        serverContent: "",
        contactPerson: "",
        contactPhone: "",
        scheduleInfoList: [],
        bookingTel: "",
        bookingLinkUrl: "",
        bookingDesc: "",
        bookingImg: "",
        bookingVideo: "",
        subImg: null,
        openDaysList: [],
        districtCode: "", //区域code
        streetId: "", //街道id
        structuringId: "", //社区id
      },
      communityId: null,
      districtListOptions: [], //区域下拉列表
      streetListOptions: [], //街道下拉列表
      structuringOptions: [], //社区下拉列表
      placeTypeOptions: [],
      statusOptions: [],
      equipInputVisible: false,
      equipInputValue: "",
      dialogFormVisible: false,
      tempScheduleObj: {
        index: null,
        beginTime: null,
        endTime: null,
        beginTimeDate: null,
        endTimeDate: null,
        schedulePrice: null,
      },
      firstIn: false,
      firstIn1: false,
    };
  },
  components: {},
  async mounted() {
    await this.getKindsList();
    this.getStatusOptions();
    await this.getStreetCommunityList();

    const { placeId, communityId } = this.$route.query;
    this.firstIn = true;
    this.firstIn1 = true;
    if (placeId !== undefined && this.allList != []) {
      this.$nextTick(() => {
        this.$refs.formPanel.getData({ placeId });
      });
    } else {
      this.addGetData();
    }
    this.$setBreadList(placeId ? "编辑" : "新增");
  },
  methods: {
    changePayType() {
      if (this.form.payType == 2) {
        this.form.scheduleInfoList.forEach((ele) => {
          ele.schedulePrice = null;
        });
      }
    },
    addGetData() {
      let params = {
        tenantId: this.$store.state.app.userInfo.tenantId,
      };
      this.$axios
        .get(`${getCommunityListByTenantIdUrl}`, { params: params })
        .then((res) => {
          if (res.code === 200) {
            this.form.districtCode = res.data.districtCode; //区域code
            this.form.streetId = res.data.streetId; //街道id
            this.form.structuringId = res.data.structuringId; //社区id
          }
        });
    },
    categoryIdChange() {
      this.getPlaceTypeOptions();
    },
    districtCodeclear() {
      //清空区级内容
      this.form.districtCode = null;
      this.form.streetId = null;
      this.form.structuringId = null;
    },
    streetIdClear() {
      //清空街道内容
      this.form.streetId = null;
      this.form.structuringId = null;
    },
    structuringIdClear() {
      //清空社区内容
      this.form.structuringId = null;
    },
    streetIdChange(value) {
      if (!this.firstIn1) {
        this.form.structuringId = null;
      } else {
        this.firstIn1 = false;
      }
      //选择街道change
      this.structuringOptions = [];
      this.streetListOptions.forEach((item, index) => {
        if (item.streetId == value) {
          this.structuringOptions = item.list;
        }
      });
    },
    districtCodeChange(value) {
      if (!this.firstIn) {
        this.form.streetId = null;
        this.form.structuringId = null;
      } else {
        this.firstIn = false;
      }
      //选择区级change
      this.streetListOptions = [];
      this.districtListOptions.forEach((item, index) => {
        if (item.districtCode == value) {
          this.streetListOptions = item.list;
        }
      });
    },
    async getStreetCommunityList() {
      //获取区县/街道/社区列表
      this.districtListOptions = [];
      let res = await this.$axios.get(`${getStreetCommunityListUrl}`);
      if (res.code === 200) {
        this.allList = res.data;
        res.data.forEach((item) => {
          item.label = item.districtName;
          item.value = item.districtCode;
          item.list.forEach((item1) => {
            item1.label = item1.streetName;
            item1.value = item1.streetId;
            item1.list.forEach((item2) => {
              item2.label = item2.structuringName;
              item2.value = item2.structuringId;
            });
          });
          this.districtListOptions.push(item);
        });
        this.districtListOptions.forEach((item) => {
          if (item.value == this.form.districtCode) {
            this.streetListOptions = item.list;
            this.streetListOptions.forEach((item1) => {
              if (item1.value == this.form.streetId) {
                this.structuringOptions = item1.list;
              }
            });
          }
        });
      }
      // });
    },
    outRoom() {
      this.form.spaceTxt = "";
      this.form.spaceId = "";
      this.form.oSpaceId = "";
    },
    async confirm() {
      if (this.form.isLast != 1) {
        this.$message.error("请选择到最后一个层级");
        return;
      }
      await this.getSpaceDetail();
      this.isdialog = false;
    },
    changeModel(value) {
      this.form.oSpaceId = value.spaceId;
      this.form.isLast = value.isLast;
      console.log(value, "++++");
    },
    changeData(value) {
      console.log(value, "++++");
    },
    async getSpaceDetail() {
      let params = {
        spaceId: this.form.oSpaceId,
      };
      let res = await this.$axios.post(
        `${getRentDetailUrl}`,
        this.$qs.stringify(params)
      );
      if (res.code === 200) {
        this.form.spaceId = this.form.oSpaceId;
        this.form.spaceTxt = res.data.path;
      } else {
        return;
      }
    },
    addRoom() {
      this.isdialog = true;
      this.$nextTick(() => {
        this.$refs.areaSelect.searchParam.specifyCollectionTypes = "1";
        this.$refs.areaSelect.changeUserType();
      });
    },
    async getKindsList() {
      let params = {};
      this.mapTypeList = [];
      let res = await this.$axios.get(`${getPlaceTypeUrl}`);
      if (res.code === 200) {
        res.data.forEach((item) => {
          item.label = item.categoryName;
          item.value = item.id;
          this.mapTypeList.push(item);
        });
      }
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
      if (
        this.form.isActivityRoom == 0 ||
        this.form.isActivityRoom == undefined
      ) {
        this.form.isActivityRoom = 0;
      } else {
        this.form.isActivityRoom;
      }
      this.form.placePicUrls = "";
      if (this.form.pictureBeanList && this.form.pictureBeanList.length > 0) {
        this.form.pictureBeanList.forEach((item) => {
          this.form.placePicUrls = this.form.placePicUrls + item.url + ",";
        });
        if (this.form.placePicUrls.length > 0) {
          this.form.placePicUrls = this.form.placePicUrls.substr(
            0,
            this.form.placePicUrls.length - 1
          );
        }
      }

      if (this.form.openTime && this.form.openTime.length > 0) {
        this.form.openDaysList = this.form.openTime.split(",");
      } else {
        this.form.openDaysList = [];
      }

      if (this.form.equip) {
        this.form.equipList = this.form.equip.split(",");
      } else {
        this.form.equipList = [];
      }

      if (this.form.endDate && this.form.endDate.length > 0) {
        this.form.endDate = new Date(this.form.endDate);
      }
      this.form.communityId = String(this.form.communityId);
      this.getPlaceTypeOptions();
      if (this.form.spaceId) {
        this.form.oSpaceId = this.form.spaceId;
        this.getSpaceDetail();
      }
    },
    submitBefore() {
      let params = this.form;

      if (!this.form.placePicUrls || this.form.placePicUrls.length == 0) {
        this.$message.error("请上传场地图片");
        return;
      }

      this.form.openTime = this.form.openDaysList.join(",");
      this.form.placePicList = this.form.placePicUrls.split(",");

      return true;
    },
    submitSuccess() {
      return true;
    },

    async getPlaceTypeOptions() {
      // if (this.form.communityId) {
      let params = {
        // communityId: this.form.communityId,
        categoryId: this.form.categoryId,
      };
      this.placeTypeOptions = [];
      let res = await this.$axios.get(`${getPlaceTypeListUrl}`, {
        params: params,
      });
      if (res.code === 200) {
        this.placeTypeOptions = [];
        res.data.forEach((item) => {
          item.label = item.typeName;
          item.value = item.id;
          this.placeTypeOptions.push(item);
        });
      }
      // }
    },

    getStatusOptions() {
      placeStatus.forEach((item) => {
        // 过滤掉删除
        if (item.code != "2") {
          item.value = item.code;
          this.statusOptions.push(item);
        }
      });
    },

    handleEquipClose(item) {
      let idx = this.form.equipList.indexOf(item);
      this.form.equipList.splice(idx, 1);
    },

    showEquipInput() {
      this.equipInputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleEquipInputConfirm() {
      let inputValue = this.equipInputValue;
      if (inputValue) {
        if (!inputValue || inputValue == "") {
          return;
        }

        if (this.form.equipList.indexOf(inputValue) < 0) {
          this.form.equipList.push(inputValue);
        }

        this.equipInputValue = "";
        this.equipInputVisible = false;
      }
    },

    showScheduleAddView() {
      this.tempScheduleObj = {
        index: null,
        beginTime: null,
        endTime: null,
        beginTimeDate: null,
        endTimeDate: null,
        schedulePrice: null,
      };

      this.dialogFormVisible = true;
    },

    showScheduleEditView(index) {
      let data = this.form.scheduleInfoList[index];
      let now = new Date().format("yyyy-MM-dd");
      if (data.beginTime.length == 5) {
        data.beginTimeDate = now + " " + data.beginTime + ":00";
      } else {
        data.beginTimeDate = data.beginTime;
      }
      if (data.endTime.length == 5) {
        data.endTimeDate = now + " " + data.endTime + ":00";
      } else {
        data.endTimeDate = data.endTime;
      }

      this.tempScheduleObj = {
        index: index,
        beginTime: data.beginTime,
        endTime: data.endTime,
        beginTimeDate: data.beginTimeDate,
        endTimeDate: data.endTimeDate,
        schedulePrice: data.schedulePrice,
      };

      this.dialogFormVisible = true;
    },
    deleteScheduleTempData(index) {
      let data = this.form.scheduleInfoList[index];
      this.$confirm(
        "确定要删除" + data.beginTime + "~" + data.endTime + "?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.form.scheduleInfoList.splice(index, 1);
        })
        .catch(() => {});
    },
    saveScheduleTempData() {
      if (!this.tempScheduleObj.beginTimeDate) {
        this.$message.error("请选择开始时间");
        return;
      }
      if (!this.tempScheduleObj.endTimeDate) {
        this.$message.error("请选择结束时间");
        return;
      }

      if (
        this.tempScheduleObj.beginTimeDate >= this.tempScheduleObj.endTimeDate
      ) {
        this.$message.error("开始时间必须小于结束时间");
        return;
      }

      if (this.tempScheduleObj.beginTimeDate instanceof Date) {
      } else {
        this.tempScheduleObj.beginTimeDate = new Date(
          this.tempScheduleObj.beginTimeDate
        );
      }
      if (this.tempScheduleObj.endTimeDate instanceof Date) {
      } else {
        this.tempScheduleObj.endTimeDate = new Date(
          this.tempScheduleObj.endTimeDate
        );
      }
      this.tempScheduleObj.beginTime =
        this.tempScheduleObj.beginTimeDate.format("HH:mm");
      this.tempScheduleObj.endTime =
        this.tempScheduleObj.endTimeDate.format("HH:mm");
      if (this.tempScheduleObj.beginTimeDate && this.tempScheduleObj.endTime) {
        for (let i = 0; i < this.form.scheduleInfoList.length; i++) {
          if (i != this.tempScheduleObj.index) {
            let scheduleObj = this.form.scheduleInfoList[i];
            if (
              (this.tempScheduleObj.beginTime <= scheduleObj.beginTime &&
                this.tempScheduleObj.endTime >= scheduleObj.beginTime) ||
              (this.tempScheduleObj.beginTime <= scheduleObj.endTime &&
                this.tempScheduleObj.endTime >= scheduleObj.endTime) ||
              (this.tempScheduleObj.beginTime >= scheduleObj.beginTime &&
                this.tempScheduleObj.endTime <= scheduleObj.endTime)
            ) {
              if (this.tempScheduleObj.index == null) {
                this.$message.error("所有场次的时间不能重叠");
                return;
              } else {
                if (scheduleObj.index != this.tempScheduleObj.index) {
                  this.$message.error("所有场次的时间不能重叠");
                  return;
                }
              }
            }
          }
        }
      } else {
        for (let i = 0; i < this.form.scheduleInfoList.length; i++) {
          let scheduleObj = this.form.scheduleInfoList[i];
          if (
            (this.tempScheduleObj.beginTime <= scheduleObj.beginTime &&
              this.tempScheduleObj.endTime >= scheduleObj.beginTime) ||
            (this.tempScheduleObj.beginTime <= scheduleObj.endTime &&
              this.tempScheduleObj.endTime >= scheduleObj.endTime) ||
            (this.tempScheduleObj.beginTime >= scheduleObj.beginTime &&
              this.tempScheduleObj.endTime <= scheduleObj.endTime)
          ) {
            if (this.tempScheduleObj.index == null) {
              this.$message.error("所有场次的时间不能重叠");
              return;
            } else {
              if (scheduleObj.index != this.tempScheduleObj.index) {
                this.$message.error("所有场次的时间不能重叠");
                return;
              }
            }
          }
        }
      }

      if (this.tempScheduleObj.index == null) {
        this.tempScheduleObj["index"] = this.form.scheduleInfoList.length;
        this.form.scheduleInfoList.push(this.tempScheduleObj);
      } else {
        this.$set(
          this.form.scheduleInfoList,
          this.tempScheduleObj.index,
          this.tempScheduleObj
        );
      }
      if (this.form.payType == 1) {
        if (
          !this.tempScheduleObj.schedulePrice &&
          this.tempScheduleObj.schedulePrice !== 0
        ) {
          this.$message.error("请填写费用");
          return;
        }
      }
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.placeEdit {
  box-sizing: border-box;
  height: 100%;

  // /deep/ .form-panel-container .el-form-item__content .v-ueditor {
  //   width: 400px !important;
  //   height: 300px;
  // }

  .address {
    .item {
      display: flex;
      margin-bottom: 10px;
      align-items: center;

      .item-l {
        width: 200px;
        font-size: 14px;
        line-height: 16px;
        overflow-x: auto;
      }

      .item-r {
        margin-left: 20px;

        .v-control {
          margin-right: 10px;
        }
      }
    }
  }
  .input-new-tag {
    width: 100%;
    ::v-deep .el-input {
      width: 100% !important;
    }
  }
}
</style>
